
/* Home page modal */
  
  .frame .overlap {
    background-image: url(https://c.animaapp.com/7klqqgt2/img/group@2x.png);
    background-size: 100% 100%;
    /* height: 453px;
    left: 4px;
    position: absolute;
    top: 0; */
    /* width: 100%; */
    height: 430px;
  left: 0;
  position: absolute;
  top: 10rem;
  width: 100%;
  }
  
  .frame .overlap-group {
    height: 277px;
    /* left: 57px; */
    position: absolute;
    top: 150px;
    width: 100%;
    margin: auto;
    left: 3rem;
  }

  
  .frame .vector {
    height: 23px;
    left: 237px;
    position: absolute;
    top: 75px;
    width: 13px;
  }
  
  .frame .img {
    height: 15px;
    left: 66px;
    position: absolute;
    top: 33px;
    width: 12px;
  }
  
  .frame .div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    position: absolute;
    top: -10%;
    width: 80%;
    left: -2%;
  }
  
  .frame .group {
    /* height: 139.78px; */
    height: 33%;
    position: relative;
    /* width: 121px; */
    width: 33%;
  }
  
  .frame .div-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }
  
  .frame .div-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    /* gap: 24px; */
    position: relative;
  }
  
  .frame .div-wrapper {
    /* align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative; */
  }
  
  .frame .text-wrapper {
    color: #ffffff;
    font-family: "Hero New-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .frame .overlap-group-wrapper {
    background: linear-gradient( 180deg, rgb(255, 185, 57) 0%, rgb(255, 229, 75) 100% );
    width: 80%;
    height: 55px;
    border-radius: 16px;
    background: linear-gradient(90deg, #FFB939 0%, #FFE54B 100%);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }
  
  .frame .overlap-group-2 {
    border-radius: 11px;
    height: 44px;
    left: 4px;
    position: relative;
    top: 4px;
    width: 302px;
  }
  
  .frame .text-wrapper-2 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 71px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 4px;
    white-space: nowrap;
  }
  
  .frame .div-wrapper-2 {
    align-items: center;
    background: radial-gradient( 50% 50% at 50% 50%, rgb(255, 185, 57) 0%, rgb(255, 229, 75) 100% );
    display: flex;
    width: 65%;
    height: 24px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 11px;
    border: 2px solid #EA5514;
    background: radial-gradient(1492.45% 50% at 50% 51.22%, #FFB939 0%, #FFE54B 100%);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .frame .text-wrapper-3 {
    color: #000000;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .p {
    color: #ffffff;
    font-family: "Hero New-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 269px;
  }
  
  .frame .text-wrapper-4 {
    color: #ffffff;
    font-family: "Hero New-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    /* left: 61px; */
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 72px;
    width: 100%;
  }
  
  /* .frame .x {
    align-items: center;
    background-color: #c6c6c6;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    gap: 10px;
    height: 44px;
    justify-content: center;
    left: 197px;
    overflow: hidden;
    padding: 8px;
    position: absolute;
    top: 502px;
    width: 44px;
  } */

  .frame .x {
    align-items: center;
    background-color: #c6c6c6;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    gap: 10px;
    height: 44px;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    position: absolute;
    top: 50%;
    width: 44px;
    margin: 2% 0% 0% -5%;  }
  
  .frame .vector-2 {
    height: 14.5px;
    position: relative;
    width: 14.5px;
  }
  

  /* questionnaire modal */
  